import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Form/CancelButton.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "h-12 text-left cursor-pointer pl-3" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { class: "ml-3" };
const _hoisted_5 = {
  class: "text-gray-200 text-xs",
  "data-cy": "order-ticker-name-table-data"
};
const _hoisted_6 = { class: "h-12 text-left" };
const _hoisted_7 = { class: "h-12 font-mono text-right" };
const _hoisted_8 = { class: "h-12 text-right font-mono" };
const _hoisted_9 = { class: "h-12 text-right font-mono" };
const _hoisted_10 = { class: "h-12" };
const _hoisted_11 = { class: "flex items-center justify-end" };
const _hoisted_12 = {
  key: 0,
  class: "ml-1 text-xs"
};
const _hoisted_13 = {
  key: 0,
  class: "h-12 text-right font-mono"
};
const _hoisted_14 = {
  key: 0,
  class: "flex items-center justify-end text-xs",
  "data-cy": "derivative-order-leverage-table-data"
};
const _hoisted_15 = /* @__PURE__ */ _createElementVNode("span", { class: "text-gray-300 text-xs" }, "\xD7", -1);
const _hoisted_16 = {
  key: 1,
  class: "text-gray-400 text-xs",
  "data-cy": "derivative-order-no-leverage-table-data"
};
const _hoisted_17 = { class: "h-12 font-mono text-right" };
const _hoisted_18 = { class: "text-xs text-gray-500" };
const _hoisted_19 = { class: "h-12 relative text-right pr-3" };
const _hoisted_20 = { class: "flex items-center justify-end" };
const _hoisted_21 = {
  key: 2,
  class: "inline-block"
};
import { Status, StatusType } from "@injectivelabs/utils";
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    isSpot: Boolean,
    order: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const { t } = useLang();
    const route = useRoute();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const isBinaryOptionsPage = route.name === "binary-options-binaryOption";
    const status = reactive(new Status(StatusType.Idle));
    const {
      isBuy,
      price,
      total,
      market,
      quantity,
      leverage,
      priceDecimals,
      orderFillable,
      filledQuantity,
      quantityDecimals,
      unfilledQuantity,
      filledQuantityPercentageToFormat
    } = useOrder(
      computed(() => props.order),
      computed(() => props.isSpot)
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    function onCancelOrder() {
      status.setLoading();
      if (props.isSpot) {
        return spotStore.cancelOrder(props.order).then(() => {
          success({ title: t("trade.order_success_canceling") });
        }).catch($onError).finally(() => {
          status.setIdle();
        });
      }
      derivativeStore.cancelOrder(props.order).then(() => {
        success({ title: t("trade.order_success_canceling") });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      const _component_PartialsTradingFormCancelButton = __nuxt_component_3;
      return _unref(market) ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        "data-cy": "order-table-row-" + _unref(market).ticker
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createVNode(_component_NuxtLink, {
            class: "flex items-center justify-start",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _unref(market) && _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_CommonTokenIcon, {
                  token: _unref(market).baseToken,
                  md: ""
                }, null, 8, ["token"])
              ])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(market).ticker), 1)
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("td", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-xs", {
              "text-green-500": _unref(isBuy),
              "text-red-500": !_unref(isBuy)
            }]),
            "data-cy": "order-order-side-table-data"
          }, _toDisplayString(_unref(isBuy) ? _ctx.$t("trade.buy") : _ctx.$t("trade.sell")), 3)
        ]),
        _createElementVNode("td", _hoisted_7, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "order-price-table-data",
            decimals: _unref(priceDecimals),
            number: _unref(price)
          }, null, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_8, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "order-quantity-table-data",
            decimals: _unref(quantityDecimals),
            number: _unref(quantity)
          }, null, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_9, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "order-unfilled-quantity-table-data",
            decimals: _unref(quantityDecimals),
            number: _unref(unfilledQuantity)
          }, null, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_AppNumber, {
              xs: "",
              "data-cy": "order-filled-quantity-table-data",
              decimals: _unref(quantityDecimals),
              number: _unref(filledQuantity)
            }, null, 8, ["decimals", "number"]),
            _unref(filledQuantity).gt("0") && __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_12, " (" + _toDisplayString(_unref(filledQuantityPercentageToFormat)) + "%) ", 1)) : _createCommentVNode("", true)
          ])
        ]),
        !isBinaryOptionsPage ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
          _unref(leverage).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
            _createTextVNode(_toDisplayString(_unref(leverage).toFormat(2)) + " ", 1),
            _hoisted_15
          ])) : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t("trade.not_available_n_a")), 1))
        ])) : _createCommentVNode("", true),
        _createElementVNode("td", _hoisted_17, [
          _createVNode(_component_AppNumber, {
            xs: "",
            "data-cy": "order-total-table-data",
            decimals: _unref(priceDecimals),
            number: _unref(total)
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ]),
            _: 1
          }, 8, ["decimals", "number"])
        ]),
        _createElementVNode("td", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            false ? (_openBlock(), _createBlock(_component_NuxtLink, {
              key: 0,
              to: _unref(marketRoute),
              class: "cursor-pointer text-blue-500 mr-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.view")), 1)
              ]),
              _: 1
            }, 8, ["to"])) : _createCommentVNode("", true),
            _unref(orderFillable) ? (_openBlock(), _createBlock(_component_PartialsTradingFormCancelButton, {
              key: 1,
              status: _unref(status),
              "data-cy": "order-cancel-link",
              onClick: onCancelOrder
            }, null, 8, ["status"])) : (_openBlock(), _createElementBlock("span", _hoisted_21, "\u2014"))
          ])
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
